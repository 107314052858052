import { GlobalStyles } from '@mui/material';
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import SyncService, { SyncEvent } from 'services/sync-service';
import { makeStyles } from 'tss-react/mui';

import LazyFallback from 'components/LazyFallback/LazyFallback';
import NotFoundRedirect from 'components/NotFoundRedirect/NotFoundRedirect';
import { useAppDispatch, useAppSelector } from 'store';
import recipeStore from 'store/recipes';
import { hasRecipes } from 'store/recipes/selectors';

import ErrorPage from './ErrorPage/ErrorPage';

const HomePage = lazy(() => import('./HomePage/HomePage'));
const RecipePage = lazy(() => import('./RecipePage/RecipePage'));
const NotFoundPage = lazy(() => import('./NotFoundPage/NotFoundPage'));
const SettingsPage = lazy(() => import('./SettingsPage/SettingsPage'));
const BottomNav = lazy(() => import('components/BottomNav/BottomNav'));
const SearchOverlay = lazy(() => import('components/SearchOverlay/SearchOverlay'));
const CategoryOverlay = lazy(() => import('components/CategoryOverlay/CategoryOverlay'));

const syncService = SyncService.get();

const App = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const ready = useAppSelector((state) => state.recipes.ready);
  const errors = useAppSelector((state) => state.recipes.errors);
  const hasData = useAppSelector(hasRecipes);

  React.useEffect(() => {
    const unsubcribe = syncService.subscribe((event) => {
      switch (event.type) {
        case SyncEvent.Init:
          dispatch(recipeStore.actions.init(!!event.error));
          break;
        case SyncEvent.Sync:
          dispatch(recipeStore.actions.setError({ type: 'sync', value: !!event.error }));
          break;
        case SyncEvent.Categories:
          dispatch(recipeStore.actions.addCategories(event.data));
          break;
        case SyncEvent.Recipes:
          dispatch(recipeStore.actions.addRecipes(event.data));
          break;
      }
    });

    syncService.init();
    syncService.sync();
    return unsubcribe;
  }, [dispatch]);

  if (errors.init || (errors.sync && ready && !hasData)) {
    return <ErrorPage />;
  }

  return (
    <>
      <div className={classes.content}>
        <Suspense fallback={<LazyFallback variant="page" />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/kategoria/:category" element={<HomePage />} />
            <Route path="/resepti/:slug" element={<RecipePage />} />
            <Route path="/asetukset" element={<SettingsPage />} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundRedirect />} />
          </Routes>
        </Suspense>
      </div>
      <Suspense fallback={null}>
        <SearchOverlay />
        <CategoryOverlay />
        <BottomNav />
      </Suspense>
      <GlobalStyles
        styles={(theme) => ({
          'html body': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
            textSizeAdjust: 'none',
          },
          html: {
            '@media print': {
              fontSize: 12,
            },
          },
        })}
      />
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  content: {
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 56,
    },
  },
}));

export default App;
